/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";

export const ThumbnailContext = React.createContext();

const ThumbnailProvider = (props) => {
    const [imageUrl, setImageUrl] = useState(null);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [selectedThumbnail, setSelectedThumbnail] = useState("");
    return (
        <ThumbnailContext.Provider
            value={{
                imageUrl, setImageUrl,
                selectedFrame, setSelectedFrame,
                selectedThumbnail, setSelectedThumbnail
            }}
        >
            {props.children}
        </ThumbnailContext.Provider>
    );
};

export default ThumbnailProvider;
