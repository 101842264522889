import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Alert, Overlay ,Tooltip } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "src/_metronic";
import { IUser } from "src/types/Types";

const tempImages = [
    toAbsoluteUrl("/media/products/prioritySuppot1.png"),
    toAbsoluteUrl("/media/products/prioritySuppot2.png"),
    toAbsoluteUrl("/media/products/prioritySuppot3.png")
]
const InfoModal = ({ show, onHide, title, message, message2, videoUrl = "", images = tempImages, isActivated }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const baseUrl = window.location.origin;
    const user: IUser = useSelector((state: any) =>
            state.auth.user && state.auth.user.parent
                ? state.auth.user.parent
                : state.auth.user
    );
    const [index, setIndex] = useState(0);
    const [showTooltip, setShowTooltip] = useState(false);
    const [salesquantity, setSalesquantity] = useState(0);
    const targetRef = useRef<any>(null);
    const clickTrustRef = useRef<any>(null);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };  
    const codeSnippet = `
    <script type="text/javascript">
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "${baseUrl}/js/visitor.js";
    var element = document.head;
    element.appendChild(script);
    </script>`;   

    const clickTrustSnippet = `<script src="https://crawler-woad-nine.vercel.app/"></script>`;

    const handleCopy = async (event, snippet) => {
        try {
            await navigator.clipboard.writeText(snippet);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 1500);
        } catch (error) {
            console.error("Failed to copy: ", error);
        }
    };

    useEffect(() => {
        if (user.sub && user.sub.line_items) {
            user.sub.line_items.forEach((rec) => {
    
                if(rec.name.includes("SALES Connect")){
                    setSalesquantity(rec.quantity);
                }
            });
        }
    }, [user]);

    const embedUrl = `${videoUrl}?&theme=light&color=white`;
    return (
        <>
        <style>
            {`
            .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
                top: 0;
                border-width: 0.4rem 0.4rem 0.4rem 0.4rem;
                border-top-color: rgb(83, 133, 225) !important;
            }
            .tooltip{
                z-index: 9999;
            }
            `}
        </style>
        <Modal style={{ zIndex: 9999 }} show={show} onHide={onHide} animation={true} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>{t(`${title}`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {videoUrl ? (
                    <>
                    {t(`${message}`)}
                    <br />
                        {title === "Click Trust" && (
                            <>
                                {message2 && message2.includes("€") && <br />}
                                {message2 && t(message2)}
                            </>
                        )}

                        {isActivated && title === "Click Trust" && (
                            <>
                                <Alert variant="success" className="mt-3" style={{marginLeft: '5px', marginRight: '5px'}}>
                                    {t('upgradeModal.successMessage')}
                                </Alert>
                                <pre  
                                    className="cursor-pointer rounded"
                                    ref={clickTrustRef}
                                    onClick={(e) => handleCopy(e, clickTrustSnippet)}
                                    style={{
                                        backgroundColor: '#f8f9fa',
                                        padding: "17px",
                                        border: '1px solid #dee2e6',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word',
                                        textAlign: 'center',
                                        margin: '0 6px'
                                    }}
                                >
                                    {clickTrustSnippet}
                                </pre>
                                <Overlay target={clickTrustRef.current} show={showTooltip} placement="top">
                                    {(props) => (
                                        <Tooltip
                                            id="tooltip-copied"
                                            {...props}
                                            arrowProps={{
                                                ref: props.arrowProps.ref,
                                                style: props.arrowProps.style,
                                            }}
                                        >
                                            {t("refer.index.copied")}
                                        </Tooltip>
                                    )}
                                </Overlay>
                            </>
                        )}

                        {isActivated && title === "SALES Connect" && (
                            <>
                                <Alert variant="success" className="mt-3" style={{marginLeft: '5px', marginRight: '5px'}}>
                                    {t("upgradeModal.successMessage-sales1")}
                                    <br />
                                    {t("upgradeModal.successMessage-sales2")}
                                </Alert>

                                {salesquantity > 0 && (
                                    <>
                                        <pre
                                            className="cursor-pointer rounded"
                                            ref={targetRef}
                                            onClick={(e) => handleCopy(e, codeSnippet)}
                                            style={{
                                                backgroundColor: '#f8f9fa',
                                                paddingBottom: "17px",
                                                border: '1px solid #dee2e6',
                                                whiteSpace: 'pre-wrap',
                                                wordBreak: 'break-word',
                                                margin: '0 6px'
                                            }}
                                        >
                                            {codeSnippet}
                                        </pre>

                                        <Overlay target={targetRef.current} show={showTooltip} placement="top">
                                            {(props) => (
                                                <Tooltip
                                                    id="tooltip-copied"
                                                    {...props}
                                                    arrowProps={{
                                                        ref: props.arrowProps.ref,
                                                        style: props.arrowProps.style,
                                                    }}
                                                >
                                                    {t("refer.index.copied")}
                                                </Tooltip>
                                            )}
                                        </Overlay>
                                    </>
                                )}
                            </>
                        )}

                    <div className="video-container mt-4" style={{padding: '0 6px'}}>
                        <iframe
                            width="100%"
                            height="266px"
                            src={embedUrl}
                            title={`${title} Video`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{borderRadius: '4px'}}
                        ></iframe>
                    </div>
                    </>
                ) : (
                    <>
                        {t(`${message}`)}
                        <br />
                        {message2 && message2.includes("€") && <br />}
                        {message2 && t(`${message2}`)}
                        <br />
                        {title === "Priority Support" && images && images.length > 0 && (
                            <Carousel
                                prevIcon={<span aria-hidden="true" className="carousel-control-custom-prev-icon" />}
                                nextIcon={<span aria-hidden="true" className="carousel-control-custom-next-icon" />}
                                activeIndex={index}
                                onSelect={handleSelect}
                            >
                                {images.map((file, idx) => (
                                    <Carousel.Item key={idx}>
                                        <img
                                            height="350px"
                                            width="auto"
                                            className="d-block w-100"
                                            src={file}
                                            alt={`${idx} slide`}
                                        />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
                    </>
                )}
            </Modal.Body>
            {/* <Modal.Body>
                {t(`${message}`)}
                <br />
                {message2 && message2.includes('€') && <br />}
                {message2 && t(`${message2}`)}
                <br />
                {title === "Priority Support" && images && images.length > 0 && <Carousel prevIcon={<span aria-hidden="true" className="carousel-control-custom-prev-icon" />} nextIcon={<span aria-hidden="true" className="carousel-control-custom-next-icon" />} activeIndex={index} onSelect={handleSelect}>
                    {images.map((file, idx) => (
                        <Carousel.Item key={idx} >
                            <img
                                height="350px" width="auto"
                                className="d-block w-100"
                                src={file}
                                alt={`${idx} slide`}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>}
            </Modal.Body> */}
            {title === t("categoryList.onlineShop") && (
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={() => history.push("/integrations/merchant")}
                    >
                        {t("adWizard.connect")}
                    </Button>
                </Modal.Footer>
            )}
        </Modal >
        </>
    )
}

export default InfoModal;