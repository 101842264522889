import { detectAnyAdblocker } from "just-detect-adblock";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Spinner, Toast } from "react-bootstrap";
import { PopupModal, useCalendlyEventListener } from "react-calendly";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isAdBlockActive } from "src/_metronic/utils/utils";
import { getMe, updateCustomMeta } from "src/app/crud/auth.crud";
import { getJobById } from "src/app/crud/data.crud";
import { updateQualification } from "src/app/crud/qualification.crud";
import LoaderStyle from "src/app/pages/sales-connect/style/LoaderStyle";
import { updateMe as updateMeAction } from "src/app/store/auth";
import { setActiveJobs, setNotification as updateNotification } from "src/app/store/data";
import { setQualificationAlert as updateQualificationAlert } from "src/app/store/data";
import { IQualification } from "src/types/Types";
import { IUser } from "src/types/Types";
import { getStorage, setStorage } from "../../../_metronic/_helpers/LocalStorageHelpers";
import { updatePaymentInfoProvided, updatePaymentInfoValidated } from "src/app/crud/googleAds.crud";
import { LoginAsContext } from "src/app/context/LoginContext";
import ReviewModal from "./ReviewModals";
import moment from "moment";
import { mapGoogleApiErrors } from "src/app/utils/common";

const liveTourEventCalendarUrl: any = process.env.REACT_APP_EVENT_CALENDAR;
const servicePlusEventCalendarUrl: any = process.env.REACT_APP_SERVICE_PLUS_EVENT_CALENDAR;

export default function SharedMessage() {
    const userData: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    
    useEffect(() => {
		if (userData.sub && userData.sub.line_items) {
			userData.sub.line_items.forEach((rec) => {
				if (rec.name.includes("Onboarding Tour")) {
					rec.quantity >= 1 ? setUpgradePlansQuantity(true) : setUpgradePlansQuantity(false);
				}
			});
		}
	}, [userData]);
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const loaderClasses = LoaderStyle();

    const [adBlockcheck, setAdblockCheck] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [showQualification, setShowQualification] = useState<boolean>(false);
    const [notification, setNotification] = useState<any>({});
    const [qualificationAlert, setQualificationAlert] = useState<any>({});
    const [isUsingAdBlocker, setIsUsingAdBlocker] = useState<boolean>(false);
    const [mccSubAccount, setMccSubAccount] = useState<boolean>(false);
    const [paymentInfoProvided, setPaymentInfoProvided] = useState<boolean>(false);
    const [paymentInfoValidated, setPaymentInfoValidated] = useState<any>(undefined);
    const [removeMerchantCenterMessage, setRemoveMerchantCenterMessage] = useState<any>(false);
    const [isCrawlingStarted, setIsCrawlingStarted] = useState(false);
    const [liveOnboardingModel, setLiveOnboardingModel] = useState<boolean>(false);
    const [servicePlusModal, setServicePlusModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openNotify, setOpenNotify] = useState<boolean>(false);
    const [showChristmasBanner, setShowChristmasBanner] = useState<boolean>(false);
    const [upgradePlansQuantity, setUpgradePlansQuantity] = useState<boolean>(false);
    const isAdSpaceSubscribed = userData.sub && userData.sub.line_items &&
    userData.sub.line_items.some((rec) => (rec.name === "AdSpace"));

    const isGoogleAccountIntegrated = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.googleAdsIntegrated
            : state.auth.user.googleAdsIntegrated
    );

    const isGoogleMerchantIntegrated = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.googleMerchantIntegrated
            : state.auth.user.googleMerchantIntegrated
    );

    const isShopAccount = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent.isShopAccount : state.auth.user.isShopAccount
    );

    const merchantAccountLinkStatus = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.merchantAccountLinkStatus
            : state.auth.user.merchantAccountLinkStatus
    );

    const qualificationInfoMessage = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.qualificationInfoMessage
            : state.auth.user.qualificationInfoMessage
    );

    const liveTourAppointment = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.liveTourAppointment
            : state.auth.user.liveTourAppointment
    );

    const isBannerInfo: any = useSelector<boolean>((state: any) =>
        state.auth.user && state.auth.user.parent
            ? state.auth.user.parent.bannerInfo
            : state.auth.user.bannerInfo
    );

    const qualification: IQualification = useSelector((state: any) => state.activeAccount.qualification);

    const accIdx = useSelector((state: any) => state.activeAccount.idx);

    const notificationData = useSelector<{ type: string }>((state: any) => state.data.notification) as any;

    const qualificationAlertData = useSelector<{ type: string }>((state: any) => state.data.qualification) as any;

    const jobsData = useSelector<{ type: string }>((state: any) => state.data.jobs) as any;

    const user = useSelector<{ type: string }>((state: any) =>
        state.auth && state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    ) as any;

    const platformList = useSelector((state: any) => state.platforms?.list);

    const { userType } = useContext(LoginAsContext);

    const qualificationInfoIdx = user.qualificationInfo.find((val) => val.idx === accIdx);

    useEffect(() => {
        if (user) {
            const googleAdsAccount = user.googleAdsAccount;

            setRemoveMerchantCenterMessage(user.removeMerchantCenterMessage);
            setIsCrawlingStarted(user.isCrawlerWorking);

            if (googleAdsAccount && googleAdsAccount.adcMcc) {
                setMccSubAccount(true);
            } else {
                setMccSubAccount(false);
            }

            if (googleAdsAccount && googleAdsAccount.hasOwnProperty("paymentInfoValidated")) {
                if (!googleAdsAccount.paymentInfoValidated && googleAdsAccount.paymentInfoProvided) {
                    setPaymentInfoValidated(googleAdsAccount.paymentInfoValidated);
                } else {
                    setPaymentInfoValidated(undefined);
                }
            } else {
                setPaymentInfoValidated(undefined);
            }

            if (googleAdsAccount && googleAdsAccount.hasOwnProperty("paymentInfoProvided")) {
                setPaymentInfoProvided(googleAdsAccount.paymentInfoProvided);
            } else {
                setPaymentInfoProvided(false);
            }

            setIsUsingAdBlocker(isAdBlockActive());
        }
    }, [user]);

    useEffect(() => {
        if (notificationData && notificationData.type) {
            setNotification(notificationData);
            setShowNotification(true);
            dispatch(updateNotification({}));
        }
    }, [notificationData, dispatch]);

    useEffect(() => {
        if (qualificationAlertData  && qualificationAlertData.type) {
            setQualificationAlert(qualificationAlertData);
            setShowQualification(true);
            dispatch(updateQualificationAlert({}));
        }
    }, [qualificationAlertData, dispatch]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (Array.isArray(jobsData) && jobsData.length > 0) {
                for (let i = 0; i < jobsData.length; i++) {
                    try {
                        await getJobById(jobsData[i]._id);
                    } catch (e) {
                        const arr = jobsData.filter((j, key) => key !== i);
                        const notif = {
                            type: "success",
                            title: t("general.success"),
                            message: t("general.successMsg"),
                        };

                        if (jobsData[i].name === "websiteScraping") {
                            notif.message = t("partials.sharedMessage.website");
                        } else if (jobsData[i].name === "competitorScraping") {
                            notif.message = t("partials.sharedMessage.competitor");
                        }
                        if (jobsData[i].name !== "sendInviteProcess") {
                            dispatch(updateNotification(notif));
                        }
                        dispatch(setActiveJobs(arr));
                    }
                }
            }
        }, 10000);

        return () => clearInterval(interval);
    }, [jobsData, dispatch, t]);

    useEffect(() => {
        const key = getStorage("adBlockbuttonhide");
        if (key === null) {
            detectAnyAdblocker().then((detected) => {
                if (detected) {
                    setAdblockCheck(detected);
                }
            });
        }
    }, []);

    const hideAdbloackNotify = () => {
        setStorage("adBlockbuttonhide", true);
        setAdblockCheck(false);
    };

    const onMerchantCancel = async () => {
        await updateCustomMeta({ key: "removeMerchantCenterMessage", value: true });
        await updateCustomMeta({ key: "isShopAccount", value: "no" });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const notificationInfoMessageCancel = async (value) => {
        await updateCustomMeta({ key: "qualificationInfoMessage", value: value });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    // useEffect(() => {
    //     if (isGoogleAccountIntegrated && !isCrawlingStarted && qualification === undefined &&
    //         (merchantAccountLinkStatus === "active" || merchantAccountLinkStatus === "unlinked")) {
    //         history.push("/integrations/qualification");
    //     }
    //     // eslint-disable-next-line
    // }, [isGoogleAccountIntegrated, isCrawlingStarted, qualification, merchantAccountLinkStatus]);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            setIsLoading(true);
            if (liveOnboardingModel) {
                setLiveOnboardingModel(false);
                await updateCustomMeta({ key: "liveTourAppointment", value: true });
                // await updateQualification({ key: "liveTourAppointment", adsAccountIdx: accIdx });
            } else {
                setServicePlusModal(false);
            }
            await updateQualification({ key: "servicePlusAppointment", adsAccountIdx: accIdx });
            const userResponse = await getMe();
            dispatch(updateMeAction(userResponse.data));
            setIsLoading(false);
            setOpenNotify(true);
        },
    });

    const handlePaymentNotificationDismissible = async () => {
        await updatePaymentInfoProvided({ paymentInfoProvided: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    }

    const handlePaymentVerifyDetailsDismissible = async () => {
        await updatePaymentInfoValidated({ paymentInfoValidated: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    }

    const handleLiveTourDismissible = async () => {
        await updateCustomMeta({ key: "liveTourAppointment", value: true });
        // await updateQualification({ key: "liveTourAppointment", adsAccountIdx: accIdx });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const handleServicePlusDismissible = async () => {
        await updateQualification({ key: "servicePlusAppointment", adsAccountIdx: accIdx });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    useEffect(() => {
        const startDate = moment.utc("2024-12-16");
        const endDate = moment.utc("2025-01-06");
        if (moment.utc().isBetween(startDate, endDate, null, '[]')) {
            setShowChristmasBanner(true);
        }
    }, []);

    const handleRemoveBanner = async (key) => {
        await updateCustomMeta({ key: key, value: true });
        const userResponse = await getMe();
        dispatch(updateMeAction(userResponse.data));
    };

    const userTextWidth = {
        maxWidth: 'calc(100% - 87px)'
    };

    const textWidth = userType === "admin" ? { maxWidth: 'calc(100% - 87px)' } : { maxWidth: '100%' };
    return (
        <>
            {showChristmasBanner && (
                <Alert variant="success">{t("partials.sharedMessage.christmasMessage")}</Alert>
            )}

            <Alert
                className={`bg-blue-gradient ${adBlockcheck ? "blinkButton" : "blinkButtonStop"}`}
                style={{ padding: '1rem 2rem' }}
                onClick={() => hideAdbloackNotify()}
            >
                {t("general.adblockActive")}
            </Alert>

            {/* access token validation alert - googleAds */}
            {!isBannerInfo?.invalidGoogleAdsAccessToken &&
                platformList.connected?.googleAds && !platformList.valid?.googleAds && (
                    userType !== "admin" ? (
                        <Link to="/integrations/google">
                            <Alert
                                className="bg-blue-gradient custom-banner"
                                dismissible={false}
                            >
                                <div className="mobilebanner" style={textWidth}>
                                    {t("partials.sharedMessage.invalidGoogleAdsAccessToken")}
                                </div>
                                <button
                                    className="upgrades-banner-icon btn shared-message-button"
                                    type="button"
                                >
                                    <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                                </button>
                            </Alert>
                        </Link>
                    ) : (
                        <Alert
                            className="bg-blue-gradient align-items-center justify-content-between"
                            style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>
                                {t("partials.sharedMessage.invalidGoogleAdsAccessToken")}
                            </div>
                            <div className="d-flex" style={{ gap: "10px" }}>
                                <button
                                    onClick={() => history.push("/integrations/google")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "10px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                    </span>
                                </button>
                                <button
                                    onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleAdsAccessToken")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "12px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                    </span>
                                </button>
                            </div>
                        </Alert>
                    )
                )}

            {/* access token validation alert - googleMerchant */}
            {!isBannerInfo?.invalidGoogleMerchantAccessToken &&
                platformList.connected?.googleMerchant && !platformList.valid?.googleMerchant && (
                    userType !== "admin" ? (
                        <Link to="/integrations/merchant">
                            {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.invalidGoogleMerchantAccessToken")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.invalidGoogleMerchantAccessToken")}</Alert> */}
                            <Alert
                                className="bg-blue-gradient custom-banner"
                                dismissible={false}
                            >
                                <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.invalidGoogleMerchantAccessToken")}</div>
                                <button
                                    className="upgrades-banner-icon btn shared-message-button"
                                    type="button"
                                >
                                    <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                                </button>
                            </Alert>
                        </Link>
                    ) : (
                        <Alert
                            className="bg-blue-gradient align-items-center justify-content-between"
                            style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>
                                {t("partials.sharedMessage.invalidGoogleMerchantAccessToken")}
                            </div>
                            <div className="d-flex" style={{ gap: "10px" }}>
                                <button
                                    onClick={() => history.push("/integrations/merchant")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "10px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                    </span>
                                </button>
                                <button
                                    onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleMerchantAccessToken")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "12px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                    </span>

                                </button>
                            </div>
                        </Alert>
                    )
                )}

            {/* access token validation alert - googleAnalytics */}
            {!isBannerInfo?.invalidGoogleAnalyticsAccessToken &&
                platformList.connected?.googleAnalytics && !platformList.valid?.googleAnalytics && (
                    userType !== "admin" ? (
                        <Link to="/integrations/analytics">
                            {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.invalidGoogleAnalyticsAccessToken")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.invalidGoogleAnalyticsAccessToken")}</Alert> */}
                            <Alert
                                className="bg-blue-gradient custom-banner"
                                dismissible={false}
                            >
                                <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.invalidGoogleAnalyticsAccessToken")}</div>
                                <button
                                    className="upgrades-banner-icon btn shared-message-button"
                                    type="button"
                                >
                                    <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                                </button>
                            </Alert>
                        </Link>
                    ) : (
                        <Alert
                            className="bg-blue-gradient align-items-center justify-content-between"
                            style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>
                                {t("partials.sharedMessage.invalidGoogleAnalyticsAccessToken")}
                            </div>
                            <div className="d-flex" style={{ gap: "10px" }}>

                                <button
                                    onClick={() => history.push("/integrations/analytics")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "10px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                    </span>
                                </button>
                                <button
                                    onClick={() => handleRemoveBanner("bannerInfo.invalidGoogleAnalyticsAccessToken")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "12px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                    </span>
                                </button>
                            </div>
                        </Alert>
                    )
                )}

            {paymentInfoValidated !== undefined && !paymentInfoValidated && (
                userType !== "admin" ? (
                    <Link to="/integrations/google">
                        {/* <Alert className="bg-blue-gradient" onClose={() => handlePaymentVerifyDetailsDismissible()} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.verifyPayment")}</Alert> */}
                        <Alert
                            className="bg-blue-gradient custom-banner"
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.verifyPayment")}</div>
                            <button
                                className="upgrades-banner-icon btn shared-message-button"
                                type="button"
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                            </button>

                        </Alert>
                    </Link>
                ) : (
                    <Alert
                        className="bg-blue-gradient align-items-center justify-content-between"
                        style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                        dismissible={false}
                    >
                        <div className="mobilebanner" style={textWidth}>
                            {t("partials.sharedMessage.verifyPayment")}
                        </div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                            <button
                                onClick={() => history.push("/integrations/google")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "10px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => handlePaymentVerifyDetailsDismissible()}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </div>
                    </Alert>
                )
            )}

            {isUsingAdBlocker ? (
                // <Alert className="bg-blue-gradient" onClose={() => setIsUsingAdBlocker(false)} dismissible>
                //     {t("partials.sharedMessage.adblock")}
                // </Alert>
                <Alert
                    className="bg-blue-gradient align-items-center justify-content-between"
                    style={{ paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    dismissible={false}
                >
                    <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.adblock")}</div>

                    {/* Custom close button */}
                    <button
                        onClick={() => setIsUsingAdBlocker(false)}
                        className="upgrades-banner-icon btn decision-button cursor-pointer"
                        type="button"
                        style={{ padding: '12px' }}
                    >
                        <span aria-hidden="true"><i className="flaticon2-delete icon-nm p-0 text-danger"></i> </span>
                    </button>
                </Alert>
            ) : ("")}

            {qualificationInfoMessage ? (
                // <Alert className="bg-blue-gradient" onClose={() => notificationInfoMessageCancel(false)} dismissible>
                //         {t("partials.sharedMessage.qualificationMessageInfo")}
                // </Alert>

                <Alert
                    className="bg-blue-gradient align-items-center justify-content-between"
                    style={{ paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    dismissible={false}
                >
                    <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.qualificationMessageInfo")}</div>

                    {/* Custom close button */}
                    <button
                        onClick={() => notificationInfoMessageCancel(false)}
                        className="upgrades-banner-icon btn decision-button cursor-pointer"
                        type="button"
                        style={{ padding: '12px' }}
                    >
                        <span aria-hidden="true"><i className="flaticon2-delete icon-nm p-0 text-danger"></i> </span>
                    </button>
                </Alert>
            ) : ("")}

            {!isBannerInfo?.googleIntegration && isGoogleAccountIntegrated === false && !location.pathname.includes("get-started") && (
                userType !== "admin" ? (
                    <Link to="/integrations/google">
                        {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleIntegration")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleIntegration")}</Alert> */}
                        <Alert
                            className="bg-blue-gradient custom-banner"
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.googleIntegration")}</div>

                            <button
                                className="upgrades-banner-icon btn shared-message-button"
                                type="button"
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                            </button>
                        </Alert>
                    </Link>
                ) : (
                    <Alert
                        className="bg-blue-gradient align-items-center justify-content-between"
                        style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                        dismissible={false}
                    >
                        <div className="mobilebanner" style={textWidth}>
                            {t("partials.sharedMessage.googleIntegration")}
                        </div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                            <button
                                onClick={() => history.push("/integrations/google")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "10px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.googleIntegration")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </div>
                    </Alert>
                )
            )}

            {isGoogleAccountIntegrated === true && isGoogleMerchantIntegrated === false &&
                !removeMerchantCenterMessage && isShopAccount ? (
                // <Alert className="bg-blue-gradient" onClose={() => onMerchantCancel()} dismissible>
                //    <Link to="/integrations/merchant" className="cursor-pointer text-white">
                //       {t("partials.sharedMessage.googleMerchantIntegration")}
                //   </Link>
                // </Alert>
                // <Link to="" className="cursor-pointer text-white">
                <Alert
                    className="bg-blue-gradient align-items-center justify-content-between"
                    style={{ paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                    dismissible={false}
                >
                    <div style={userTextWidth}>{t("partials.sharedMessage.googleMerchantIntegration")}</div>
                    <div className="d-flex" style={{ gap: '10px' }}>
                        <button
                            onClick={() => history.push("/integrations/merchant")}
                            className="upgrades-banner-icon btn decision-button cursor-pointer "
                            type="button"
                            style={{ padding: '10px' }}
                        >
                            <span aria-hidden="true"><i className="flaticon2-check-mark icon-md p-0 text-success"></i></span>
                        </button>
                        <button
                            onClick={() => onMerchantCancel()}
                            className="upgrades-banner-icon btn decision-button cursor-pointer"
                            type="button"
                            style={{ padding: '12px' }}
                        >
                            <span aria-hidden="true"><i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                            </span>
                        </button>
                    </div>
                </Alert>
                // </Link>

            ) : ("")}

            {!isBannerInfo?.googleMerchantLinked && merchantAccountLinkStatus === "pending" ? (
                // <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleMerchantLinked")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleMerchantLinked")}</Alert>
                <Alert
                    className="bg-blue-gradient align-items-center justify-content-between"
                    style={userType === "admin" ? { paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" } : {padding: '1rem 2rem'}}
                    dismissible={false}
                >
                    <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.googleMerchantLinked")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.googleMerchantLinked")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </>
                    )}
                </Alert>
            ) : ("")}

            {/* {!isBannerInfo?.adspace && isAdSpaceSubscribed ? (
                <Link to="/adspace">
                <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.adspace")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.adspaceMessage")}</Alert>
                </Link>
            ) : (
                ""
            )} */}

            {!isBannerInfo?.adspace && isAdSpaceSubscribed && (
                userType !== "admin" ? (
                    <Link to="/adspace">
                        <Alert className="bg-blue-gradient custom-banner" dismissible={false} >
                            <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.adspaceMessage")}</div>
                            <button
                                className="upgrades-banner-icon btn shared-message-button"
                                type="button"
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                            </button>
                        </Alert>
                    </Link>
                ) : (
                    <Alert
                        className="bg-blue-gradient align-items-center justify-content-between"
                        style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                        dismissible={false}
                    >
                        <div className="mobilebanner" style={textWidth}>
                            {t("partials.sharedMessage.adspaceMessage")}
                        </div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                            <button
                                onClick={() => history.push("/adspace")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "10px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.adspace")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </div>
                    </Alert>
                )
            )}

            {!isBannerInfo?.googleMerchantUnLinked && isGoogleMerchantIntegrated === true &&
                merchantAccountLinkStatus === "unlinked" &&
                history.location.pathname.includes("shopping") ? (
                // <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.googleMerchantUnLinked")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.googleMerchantUnLinked")}</Alert>
                <Alert
                    className="bg-blue-gradient align-items-center justify-content-between"
                    style={userType === "admin" ? { paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" } : {padding: '1rem 2rem'}}
                    dismissible={false}
                >
                    <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.googleMerchantUnLinked")}</div>

                    {/* Custom close button */}
                    {userType === "admin" && (
                        <>
                            <button
                                onClick={() => handleRemoveBanner("bannerInfo.googleMerchantUnLinked")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </>
                    )}
                </Alert>
            ) : ("")}

            {!liveTourAppointment && upgradePlansQuantity && (
            <>
                {/* <div style={{ position: "relative" }}> */}
                    <Alert
                        className="bg-blue-gradient align-items-center justify-content-between"
                        style={{paddingRight: '0.5rem', paddingTop: '0.5rem', paddingBottom: '0.5rem'}}
                        // onClick={() => setLiveOnboardingModel(true)}
                    >
                        <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.liveOnboardingTourMassegeInfo")}</div>
                        <div className="d-flex" style={{gap: '10px'}}>
                    <button
                        onClick={() => setLiveOnboardingModel(true)}
                        className="upgrades-banner-icon btn decision-button cursor-pointer "
                        type="button"
                        style={{padding: '10px'}}
                    >
                        <span aria-hidden="true"><i className="flaticon2-check-mark icon-md p-0 text-success"></i></span>
                    </button>
                    <button
                        className="upgrades-banner-icon btn decision-button cursor-pointer "
                        type="button"
                        onClick={() => handleLiveTourDismissible()}
                        style={{padding: '12px'}}
                    >
                        <span aria-hidden="true"><i className="flaticon2-delete icon-nm p-0 text-danger"></i></span>
                    </button>
                    </div>
                    </Alert>
                {/* </div> */}
            </>
            )}

            {qualification && qualificationInfoIdx && liveTourAppointment &&
                qualification.upgrades.includes("ServicePlus") &&
                !qualificationInfoIdx.servicePlusAppointment && (
                    userType !== "admin" ? (
                        <Alert
                            className="bg-blue-gradient custom-banner"
                            onClick={() => setServicePlusModal(true)}
                            style={{ cursor: "pointer" }}
                        >
                            <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.servicePlusMassegeInfo")}</div>
                            <button
                                className="upgrades-banner-icon btn shared-message-button"
                                type="button"
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                            </button>
                        </Alert>
                    ) : (
                        <Alert
                            className="bg-blue-gradient align-items-center justify-content-between"
                            style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>
                                {t("partials.sharedMessage.servicePlusMassegeInfo")}
                            </div>
                            <div className="d-flex" style={{ gap: "10px" }}>
                                <button
                                    onClick={() => setServicePlusModal(true)}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "10px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                    </span>
                                </button>
                                <button
                                    onClick={() => handleServicePlusDismissible()}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "12px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                    </span>
                                </button>
                            </div>
                        </Alert>
                    )
                )}

            {!isBannerInfo?.qualification && isGoogleAccountIntegrated === true &&
                !isCrawlingStarted && !qualification &&
                (merchantAccountLinkStatus === "active" || merchantAccountLinkStatus === "unlinked") && (
                    userType !== "admin" ? (
                        <Link to="/integrations/qualification">
                            {/* <Alert className="bg-blue-gradient" onClose={() => handleRemoveBanner("bannerInfo.qualification")} dismissible={userType === "admin" ? true : false}>{t("partials.sharedMessage.qualificationInfoMissing")}</Alert> */}
                            <Alert
                                className="bg-blue-gradient custom-banner"
                                dismissible={false}
                            >
                                <div className="mobilebanner" style={textWidth}>{t("partials.sharedMessage.qualificationInfoMissing")}</div>

                                <button
                                    className="upgrades-banner-icon btn shared-message-button"
                                    type="button"
                                >
                                    <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                                </button>
                            </Alert>
                        </Link>
                    ) : (
                        <Alert
                            className="bg-blue-gradient align-items-center justify-content-between"
                            style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>
                                {t("partials.sharedMessage.qualificationInfoMissing")}
                            </div>
                            <div className="d-flex" style={{ gap: "10px" }}>
                                <button
                                    onClick={() => history.push("/integrations/qualification")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "10px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                    </span>
                                </button>
                                <button
                                    onClick={() => handleRemoveBanner("bannerInfo.qualification")}
                                    className="upgrades-banner-icon btn decision-button cursor-pointer"
                                    type="button"
                                    style={{ padding: "12px" }}
                                >
                                    <span aria-hidden="true">
                                        <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                    </span>
                                </button>
                            </div>
                        </Alert>
                    )
                )}

            {mccSubAccount && !paymentInfoProvided && qualification && (
                userType !== "admin" ? (
                    <Link to="/integrations/google">
                        {/* <Alert className="bg-blue-gradient" onClose={() => handlePaymentNotificationDismissible()} dismissible={userType === "admin" ? true : false} >{t("integrations.google.paymentNotification")}</Alert> */}
                        <Alert
                            className="bg-blue-gradient custom-banner"
                            dismissible={false}
                        >
                            <div className="mobilebanner" style={textWidth}>{t("integrations.google.paymentNotification")}</div>

                            <button
                                className="upgrades-banner-icon btn shared-message-button"
                                type="button"
                            >
                                <span aria-hidden="true">{t("partials.sharedMessage.startNow")}</span>
                            </button>
                        </Alert>
                    </Link>
                ) : (
                    <Alert
                        className="bg-blue-gradient align-items-center justify-content-between"
                        style={{ paddingRight: "0.5rem", paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                        dismissible={false}
                    >
                        <div className="mobilebanner" style={textWidth}>
                            {t("integrations.google.paymentNotification")}
                        </div>
                        <div className="d-flex" style={{ gap: "10px" }}>
                            <button
                                onClick={() => history.push("/integrations/google")}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "10px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-check-mark icon-md p-0 text-success"></i>
                                </span>
                            </button>
                            <button
                                onClick={() => handlePaymentNotificationDismissible()}
                                className="upgrades-banner-icon btn decision-button cursor-pointer"
                                type="button"
                                style={{ padding: "12px" }}
                            >
                                <span aria-hidden="true">
                                    <i className="flaticon2-delete icon-nm p-0 text-danger"></i>
                                </span>
                            </button>
                        </div>
                    </Alert>
                )
            )}

            {notification && notification.type ? (
                notification?.errorType && notification?.errorType === "qualification" ? (
                    <Toast
                        className={`notif-${notification.type}`}
                        style={{ position: "fixed", right: 25, top: "65%", minWidth: 250, zIndex: 999 }}
                        onClose={() => setShowNotification(false)}
                        show={showNotification}
                        delay={15000}
                        autohide
                    >
                    <Toast.Header>
                        <strong className="mr-auto">{t(notification.title)}</strong>
                    </Toast.Header>
                    <Toast.Body>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: mapGoogleApiErrors(notification.message),
                        }}
                    />
                    </Toast.Body>
                </Toast>
                ) : (
                    <Toast
                    className={`notif-${notification.type}`}
                    style={{ position: "fixed", top: "80%", right: 25, minWidth: 250, zIndex: 999 }}
                    onClose={() => setShowNotification(false)}
                    show={showNotification}
                    delay={8000}
                    autohide
                    >
                    <Toast.Header>
                        <strong className="mr-auto">{t(notification.title)}</strong>
                    </Toast.Header>
                    <Toast.Body>{t(notification.message)}</Toast.Body>
                </Toast>
                )
            ) : ("")}

            {qualificationAlert && qualificationAlert.type && (
                <Toast
                    className={`notif-${qualificationAlert.type}`}
                    style={{ position: "fixed", top: "80%", right: 25, minWidth: 250, zIndex: 999 }}
                    onClose={() => setShowQualification(false)}
                    show={showQualification}
                    autohide={false}
                    // delay={8000}
                >
                    <Toast.Header>
                        <strong className="mr-auto">{t(qualificationAlert.title)}</strong>
                    </Toast.Header>
                    <Toast.Body>{qualificationAlert?.message[0]?.message ? qualificationAlert?.message[0]?.message : t(qualificationAlert?.message)}</Toast.Body> 
                </Toast>
            )}

            {isLoading && (
                <div className={loaderClasses.loaderWrapper}>
                    <Spinner
                        animation="border"
                        style={{ height: 40, width: 40, borderWidth: 5, color: "#5578EB" }}
                    />
                </div>
            )}
            <PopupModal
                url={liveTourEventCalendarUrl}
                rootElement={document.getElementById("root")!}
                open={liveOnboardingModel}
                onModalClose={() => setLiveOnboardingModel(false)}
                pageSettings={{
                    primaryColor: "#5D78FF"
                }}
            />
            <PopupModal
                url={servicePlusEventCalendarUrl}
                rootElement={document.getElementById("root")!}
                open={servicePlusModal}
                onModalClose={() => setServicePlusModal(false)}
                pageSettings={{
                    primaryColor: "#5D78FF"
                }}
            />
            <ReviewModal
                show={openNotify}
                onClose={() => setOpenNotify(false)}
                title={t("partials.sharedMessage.calendlyMessage")}
            />
        </>
    );
}
