import React, { useState, useReducer} from "react";
import { useLocation, useHistory } from 'react-router-dom';

export const TourContext = React.createContext();

const TourProvider = (props) => {
    const [stepIndexState, setStepIndexState] = useState(0);
    const [runTour, setRunTour] = useState(false);
    const location = useLocation();
    const history = useHistory();

    const INITIAL_STATE = {
        key: new Date(),
        run: false,
        continuous: true,
        loading: false,
        stepIndex: 0,
        steps: [],
        disableOverlayClose: true,
        disableCloseOnEsc: true,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "START":
                return {
                    ...state,
                    stepIndex: 0,
                    run: true,
                    loading: false,
                    key: new Date()
                };
            default:
                return state;
        }
    };
    const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
    const toggleSidebarAndMenu = () => {
        const sideBarToggle = document.body;
        if (sideBarToggle.classList.contains('kt-aside--minimize')) {
            sideBarToggle.classList.remove('kt-aside--minimize');
        }
        const parent = document.querySelector('.connector-li');
        if (parent) {
            parent.classList.add('kt-menu__item--open');
            const submenu = parent.querySelector('.kt-menu__submenu');
            if (submenu) submenu.style.display = 'flex';
        }
    };
    const startTour = () => {

        if (location.pathname !== '/dashboard') {
            history.push('/dashboard');
            setTimeout(() => {
                toggleSidebarAndMenu();
                setStepIndexState(0);
                setTimeout(() => {
                    setRunTour(true);
                    dispatch({ type: "START" });
                }, 250);
            }, 150);
        } else {
            toggleSidebarAndMenu();
            setStepIndexState(0);
            
            setTimeout(() => {
                setRunTour(true);
                dispatch({ type: "START" });
            }, 200);
        }
    };
    return <TourContext.Provider value={{ stepIndexState, setStepIndexState, tourState, dispatch, startTour, runTour, setRunTour }}>{props.children}</TourContext.Provider>;
};

export default TourProvider;