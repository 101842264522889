import React, { useState } from "react";

export const PlatformContext = React.createContext();

const PlatformProvider = ({ children }) => {
    const [adsType, setAdsType] = useState("googleAds");
    const [selectedRows, setSelectedRows] = useState([]);
    const [creativeData, setCreativeData] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const updateAdType = (type) => setAdsType(type);
    return (
        <PlatformContext.Provider
            value={{
                adsType,
                updateAdType,
                selectedRows,
                setSelectedRows,
                creativeData,
                setCreativeData,
                selectedFiles,
                setSelectedFiles,
            }}
        >
            {children}
        </PlatformContext.Provider>
    );
};

export default PlatformProvider;