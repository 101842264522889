/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { isEmptyObject } from "jquery";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { TabsContext } from "src/app/context/TabContext";
import { getMe, getUserMeta } from "src/app/crud/auth.crud";
import { switchActiveAccount } from "src/app/crud/googleAds.crud";
import { getCompetitors, getMedia, getOwnResources } from "src/app/store/activeAccount/activeAccountAction";
import { setQualification, switchAccount } from "src/app/store/activeAccount/activeAccountSlice";
import { updateMe as updateMeAction } from "src/app/store/auth";
import { fetchAccount, fetchAllAccounts, fetchValidateToken } from "src/app/store/platform/platform.actions";
import { IGoogleAdsAccount, IUser } from "src/types/Types";

export default function TopBarTab() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        activeAdsAccounts,
        setActiveAdsAccounts,
        selectedAccount,
        setSelectedAccount,
        // salesConnectSubscribed,
        setSalesConnectSubscribed,
    } = useContext(TabsContext);

    const user: IUser = useSelector((state: any) =>
        state.auth.user && state.auth.user.parent ? state.auth.user.parent : state.auth.user
    );
    const activeAccount = useSelector((state: any) => state.activeAccount);

    const crawlerInProgress =
        user.isCrawlerWorking ||
        activeAccount.ownResourcesInProgress ||
        activeAccount.mediaInProgress ||
        activeAccount.competitorsInProgress;
    // const [clickFraudSubscribed, isClickFraudSubscribed] = useState<boolean>(false);

    // init dispatch
    const setActiveAccount = (idx) => {
        if (user) {
            dispatch(fetchAccount());
            dispatch(fetchAllAccounts());
            dispatch(fetchValidateToken());

            dispatch(switchAccount({ idx: idx }));
            dispatch(getCompetitors({ idx: idx }));
            dispatch(getMedia({ idx: idx }));
            dispatch(getOwnResources({ idx: idx }));

            if (user.qualificationInfo) {
                const findQualification = user.qualificationInfo.find((q) => q.idx === idx);
                dispatch(setQualification({ qualification: findQualification }));
            }
        }
    };

    useEffect(() => {
        if (user) {
            const findIdx: number = user.activegoogleAdsAccounts.findIndex((acc: IGoogleAdsAccount) => {
                return String(acc.customerId) === String(user.googleAdsAccount.customerId);
            });
            setActiveAccount(findIdx);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // if (user.sub && user.sub.line_items) {
        //     user.sub.line_items.forEach((rec) => {
        //         if (rec.name === "Click Fraud" || rec.name === "Click Trust") {
        //             isClickFraudSubscribed(true);
        //         }
        //         if (rec.name === "Sales Connect") {
        //             setSalesConnectSubscribed(true);
        //         }
        //         // if (rec.name === "Business Ads") { setBusinessAdsActive(true) };
        //         // if (rec.name === "Social Ads") { setSocialAdsActive(true) };
        //     });
        // }

        getUserMeta().then((response) => {
            if (response.data.googleAdsAccount) {
                setSelectedAccount(response.data.googleAdsAccount);
                if (response.data && response.data.activegoogleAdsAccounts) {
                    setActiveAdsAccounts(response.data.activegoogleAdsAccounts);
                }
            }
        });
    }, [user, setActiveAdsAccounts, setSelectedAccount, setSalesConnectSubscribed]);
    const changeActiveAccount = (account) => {
        switchActiveAccount(account).then(async (res) => {
            const findIdx: number = user.activegoogleAdsAccounts.findIndex((acc: IGoogleAdsAccount) => {
                return String(acc.customerId) === String(account.customerId);
            });
            setActiveAccount(findIdx);

            getUserMeta().then((response) => {
                if (response.data.googleAdsAccount) {
                    setSelectedAccount(response.data.googleAdsAccount);
                    if (response.data && response.data.activegoogleAdsAccounts) {
                        setActiveAdsAccounts(response.data.activegoogleAdsAccounts);
                    }
                }
            });

            let response = await getMe();
            dispatch(updateMeAction(response.data));
        });
    };

    // const renderTooltip = (props: any) => {
    //   return (selectedAccount?.name && <Tooltip id="user-notification-tooltip" {...props}>{selectedAccount?.name}</Tooltip>)
    // };
    // const tooltipData = user && user.googleAdsAccount && (
    //     <Tooltip id="user-notification-tooltip">
    //         {user.googleAdsAccount.name ? user.googleAdsAccount.name : user.googleAdsAccount.customerId}
    //     </Tooltip>
    // );

    return (
        <>
        <style>
            {`
            .tooltip-inner {
                background: linear-gradient(150deg, rgb(70, 190, 247) 0%, rgb(83, 133, 225) 100%); !important;
                color: white;
            }

            .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
                bottom: 0;
                border-width: 0.4rem 0.4rem 0.4rem 0.4rem;
                border-bottom-color: rgb(83, 133, 225) !important;
            }

            `}
            </style>
            <div id="kt_header_menu" className="kt-header-menu kt-header-menu-mobile  kt-header-menu--layout-default responsive-topbar-menu">
                <ul className="kt-menu__nav ">
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link style={{width: '41px', height: '36px'}}
                            to="/dashboard"
                            className={`kt-menu__link kt-menu__toggle kt-home-icon mobile-home-icon ${
                                window.location.pathname === "/dashboard" ? "selected-button" : "kt-menu-item-hover"
                            }`}
                        >
                            <span style={{width: '18px', height: '16px'}}
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "home-icon"
                                }
                            >
                                <span className="svg-icon svg-icon-md" style={{width: '18px', height: '16px', display: 'block'}}>
                                    <i className="fas fa-home mobile-home-icon-responsive" style={{marginTop: "1.5px"}}/>
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link
                            to="/updates"
                            className={`kt-menu__link kt-menu__toggle ${
                                window.location.pathname === "/updates" ? "selected-button" : "kt-menu-item-hover"
                            }`}
                        >
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text ai-class"
                                }
                            >
                                {t("updates.buttonText")}
                            </span>
                        </Link>
                    </li>
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link
                            to="/ad/wizard"
                            className={`kt-menu__link kt-menu__toggle ${window.location.pathname === "/ad/wizard" ? "selected-button" : "kt-menu-item-hover"
                                }`}
                        >
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text  adwizard-class"
                                }
                            >
                                AdWizard
                            </span>
                        </Link>
                    </li>
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link
                            to="/post/wizard"
                            className={`kt-menu__link kt-menu__toggle  ${window.location.pathname === "/post/wizard" ? "selected-button" : "kt-menu-item-hover"
                                }`}
                        >
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text postwizard-class"
                                }
                            >
                                PostWizard
                            </span>
                        </Link>
                    </li>
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link
                            to="/editor"
                            className={`kt-menu__link kt-menu__toggle ${
                                window.location.pathname === "/editor" ? "selected-button" : "kt-menu-item-hover"
                            }`}
                        >
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text"
                                }
                            >
                                Editor
                            </span>
                        </Link>
                    </li>
                    <li
                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                        data-ktmenu-submenu-toggle="click"
                        aria-haspopup="true"
                    >
                        <Link
                            to="/ai/text"
                            className={`kt-menu__link kt-menu__toggle  ${
                                window.location.pathname === "/ai/text" ? "selected-button" : "kt-menu-item-hover"
                            }`}
                        >
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text textai-class"
                                }
                            >
                                {t("aside.textAI")}
                            </span>
                        </Link>
                    </li>

                    {/* {activeAdsAccounts && activeAdsAccounts.length > 0 && activeAdsAccounts.length <= 1 && (
                        <li
                            className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                            data-ktmenu-submenu-toggle="click"
                            aria-haspopup="true"
                        > */}
                            {/* <OverlayTrigger placement="right" overlay={renderTooltip}> */}
                            {/* <Link to="/dashboard" className="kt-menu__link kt-menu__toggle kt-menu-item-hover">
                                <span
                                    className={
                                        isEmptyObject(user.sub)
                                            ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                            : "kt-menu__link-text"
                                    }
                                > */}
                                    {/* {t("aside.googleAds")} */}
                                    {/* {activeAdsAccounts[0] && activeAdsAccounts[0].name
                                        ? activeAdsAccounts[0].name
                                        : activeAdsAccounts[0].customerId} */}
                                {/* </span>
                            </Link> */}
                            {/* </OverlayTrigger> */}
                        {/* </li>
                    )} */}

                    {activeAdsAccounts && activeAdsAccounts.length > 1 && (
                        <>
                            {activeAdsAccounts.length <= 2 ? (
                                activeAdsAccounts.map((account, index) => (
                                    <li
                                        key={index}
                                        className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                                        data-ktmenu-submenu-toggle="click"
                                        aria-haspopup="true"
                                    >
                                        <button
                                            title={
                                                user.qualificationProcessRunning
                                                    ? t("integrations.google.qualification.processRunning")
                                                    : crawlerInProgress
                                                        ? t("integrations.google.qualification.crawlProcess")
                                                        : ""
                                            }
                                            disabled={user.qualificationProcessRunning || crawlerInProgress}
                                            type="button"
                                            className={`kt-menu__link kt-menu__toggle ${selectedAccount?.customerId === account.customerId
                                                ? user.qualificationProcessRunning
                                                    ? "blinkAdAccountBtn"
                                                    : !crawlerInProgress
                                                        ? "active"
                                                        : ""
                                                : "kt-menu-item-hover"
                                                } `}
                                            style={{ border: "none" }}
                                            onClick={() => changeActiveAccount(account)}
                                        >
                                            <span
                                                style={{
                                                    color:
                                                        selectedAccount &&
                                                            selectedAccount.customerId === account.customerId &&
                                                            user.qualificationProcessRunning
                                                            ? "white"
                                                            : "",
                                                }}
                                                className={`kt-menu__link-text ${selectedAccount &&
                                                    selectedAccount.customerId === account.customerId &&
                                                    crawlerInProgress
                                                    ? "red-text"
                                                    : ""
                                                    }`}
                                            >
                                                {account && account.name ? account.name : account.customerId}
                                            </span>
                                        </button>
                                    </li>
                                ))
                            ) : (
                                <li
                                    className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                                    data-ktmenu-submenu-toggle="click"
                                    aria-haspopup="true"
                                >
                                    <Dropdown className="kt-menu__toggle d-flex align-items-center dropdown_parent">
                                        <Dropdown.Toggle
                                            className="dropdown-toogle-button mobile-toggle-button mobile-toogle-responsive"
                                            variant="light"
                                            id="dropdown-basic-button"
                                        >
                                            {selectedAccount.name}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {activeAdsAccounts.map((account, index) => (
                                                <Dropdown.Item
                                                    style={{
                                                        background: account.name === selectedAccount.name ? `linear-gradient(150deg, #46bef7 0%, #5385e1 100%)` : "",
                                                        color: account.name === selectedAccount.name ? "white" : "",
                                                    }}
                                                    onClick={() => changeActiveAccount(account)}
                                                    key={index}
                                                >
                                                    {account && account.name ? account.name : account.customerId}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            )}
                        </>
                    )}

                    {/* {clickFraudSubscribed && ( */}
                        <li
                            className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                            data-ktmenu-submenu-toggle="click"
                            aria-haspopup="true"
                        >
                            {user && user.googleAdsAccount ? (
                                // <OverlayTrigger placement="bottom" overlay={tooltipData}>
                                    <Link
                                        to="/clicktrust"
                                        className={`kt-menu__link kt-menu__toggle clicktrust-class ${
                                            window.location.pathname === "/clicktrust"
                                                ? "selected-button"
                                                : "kt-menu-item-hover"
                                        }`}
                                    >
                                        <span className="kt-menu__link-text">{t("blacklist.buttonText")}</span>
                                    </Link>
                                // </OverlayTrigger>
                            ) : (
                                <Link
                                    to="/clicktrust"
                                    className={`kt-menu__link kt-menu__toggle clicktrust-class ${
                                        window.location.pathname === "/clicktrust"
                                            ? "selected-button"
                                            : "kt-menu-item-hover"
                                    }`}
                                >
                                    <span
                                        className={
                                            isEmptyObject(user.sub)
                                                ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                                : "kt-menu__link-text"
                                        }
                                    >
                                        {t("blacklist.buttonText")}
                                    </span>
                                    {/* <span className="kt-menu__link-text">{t("blacklist.buttonText")}</span> */}
                                </Link>
                            )}
                        </li>
                    {/* )} */}
                    {/* {salesConnectSubscribed && ( */}
                        <li
                            className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active"
                            data-ktmenu-submenu-toggle="click"
                            aria-haspopup="true"
                        >
                            {/* <OverlayTrigger placement="right" overlay={renderTooltip}> */}
                            <Link to="/sales-connect" className={`kt-menu__link kt-menu__toggle salesconnect-class ${window.location.pathname === "/sales-connect" ? "selected-button" : "kt-menu-item-hover"}`}>
                            <span
                                className={
                                    isEmptyObject(user.sub)
                                        ? "kt-menu__link-text topBarButttonText kt-menu__link-text2"
                                        : "kt-menu__link-text"
                                }
                            >
                                {t("salesConnect.buttonText")}
                            </span>
                            {/* <span className="kt-menu__link-text">{t("salesConnect.buttonText")}</span> */}
                            </Link>
                            {/* </OverlayTrigger> */}
                        </li>
                    {/* )} */}
                    {/* {isBusinessAdsActive && (
            <li className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active" data-ktmenu-submenu-toggle="click" aria-haspopup="true">
              <button
                onClick={() => toggleLinkedinModal(true)}
                type="button"
                className={`kt-menu__link kt-menu__toggle`}
                style={{ border: "none" }}
              >
                <span className="kt-menu__link-text active">{t('aside.linkedinAds')}</span>
              </button>
            </li>
          )} */}
                    {/* {isSocialAdsActive && (
            <li className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active" data-ktmenu-submenu-toggle="click" aria-haspopup="true">
              <button
                onClick={handleShow}
                type="button"
                className={`kt-menu__link kt-menu__toggle`}
                style={{ border: "none" }}
              >
                <span className="kt-menu__link-text active">{t('aside.metaAds')}</span>

              </button>
            </li>
          )} */}
                    {/* <li className="kt-menu__item kt-menu__item--open kt-menu__item--here kt-menu__item--submenu kt-menu__item--rel kt-menu__item--active" data-ktmenu-submenu-toggle="click" aria-haspopup="true">
              <button
                onClick={() => toggleTaboolaModal(true)}
                type="button"
                className={`kt-menu__link kt-menu__toggle`}
                style={{ border: "none" }}
              >
                <span className="kt-menu__link-text active">{"Taboola Ads"}</span>
              </button>
            </li> */}
                </ul>
            </div>
        </>
    );
}
