import axios from "axios";

export function getCrawlUrlData(query = {}) {
  return axios.get("/crawlUrl/user_id", { params: query });
}

export function getCompetitorUrlData(query = {}) {
  return axios.get("/competitorUrl/user_id", { params: query });
}

export function getMediaData(query = {}) {
  return axios.get("/mediaCrawl/user_id", { params: query });
}

export function getData(query = {}) {
  return axios.get("/data/user_id", { params: query });
}

export function createData(data) {
  return axios.post("/data/", data);
}

export function deleteData(data) {
  return axios.post("/data/remove", data);
}

export function updateData(data, query = {}) {
  return axios.put("/data/user_id", data, { params: query });
}

export function createCompetitorData(data) {
  return axios.post("/data/competitor", data);
}

export function getJobById(id) {
  return axios.get(`/data/job/${id}`);
}

export function imageCrawling() {
  return axios.post("/data/crawlImage");
}

export function deleteCompetitorById(id, payload) {
  return axios.post(`/data/competitor/${id}`, payload);
}

export function crawlerUrlStart(data) {
  return axios.post("/urlCrawlStart", data);
}
export function crawlerUrlDirectStart(data) {
  return axios.post("/urlCrawlDirectStart", data);
}

export function competitorUrlStart(data) {
  return axios.post("/competitorUrlStart", data);
}

export function createCompetitorUrlData(data) {
  return axios.post("/newCompetitorUrl", data);
}

export function deleteCompetitorUrlData(data) {
  return axios.post("/deleteCompetitorUrl", data);
}

export function mediaCrawlStart(data) {
  return axios.post("/mediaCrawlStart", data);
}

export function deleteMediaData(data) {
  return axios.post("/crawler/delete_media", data);
}

export function logoCrawlStart(data) {
  return axios.post("/logoCrawlStart", data);
}