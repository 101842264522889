import React, { useState, useEffect } from "react";
import { makeStyles, Paper, Dialog, IconButton, DialogTitle } from "@material-ui/core";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Col, Row } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflowX: 'auto',
        minHeight: 500,
        padding: '25px',
    },
    preview: {
        width: "100%",
        overflow: 'hidden',
        backgroundColor: "#f7f7f7"
    },
    column: {
        flexBasis: '33.33%',
    },
    table: {
        minWidth: 400,
    },
    details: {
        display: 'block'
    },
    closeBtn: {
        cursor: "pointer",
        position: "absolute",
        right: 8,
        top: 8,
        color: grey[500],
    },
}));

const MediaCropper = ({ show, onClose, selectedFile, onCropComplete }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [activeImage, setActiveImage] = useState<string>("");
    const [valueX, setValueX] = useState<any>();
    const [valueY, setValueY] = useState<any>();
    const [valueRotate, setValueRotate] = useState<any>();
    const [valueScaleX, setValueScaleX] = useState<any>();
    const [valueScaleY, setValueScaleY] = useState<any>();
    const [cropper, setCropper] = useState<any>();
    const [valueWidth, setValueWidth] = useState<any>();
    const [valueHeight, setValueHeight] = useState<any>();
    const [errorMsg, setErrorMsg] = useState("");
    const [viewMode, setViewMode] = useState<Cropper.ViewMode>(1);
    const [options, setOptions] = useState<any>({
            responsive: true,
            restore: true,
            checkCrossOrigin: true,
            checkOrientation: true,
            modal: true,
            guides: true,
            center: true,
            highlight: true,
            background: true,
            autoCrop: true,
            movable: true,
            rotatable: true,
            scalable: true,
            zoomOnTouch: true,
            zoomOnWheel: true,
            cropBoxMovable: true,
            cropBoxResizable: true,
            toggleDragModeOnDblclick: true
        });

    const handleClose = () => {
        onClose(false);
    };

    const handleAction = (action: string) => {
        if (typeof cropper === "undefined") return;
        switch (action) {
            case "moveLeft":
                cropper.move(-10, 0);
                break;
            case "moveRight":
                cropper.move(10, 0);
                break;
            case "moveUp":
                cropper.move(0, -10);
                break;
            case "moveDown":
                cropper.move(0, 10);
                break;
            case "zoomIn":
                cropper.zoom(0.1);
                break;
            case "zoomOut":
                cropper.zoom(-0.1);
                break;
            case "dragModeMove":
                cropper.setDragMode("move");
                break;
            case "dragModeCrop":
                cropper.setDragMode("crop");
                break;
            case "rotateLeft":
                cropper.rotate(-45);
                break;
            case "rotateRight":
                cropper.rotate(45);
                break;
            case "flipHorizontal":
                cropper.scaleX(-1);
                break;
            case "flipVertical":
                cropper.scaleY(-1);
                break;
            case "setCrop":
                cropper.crop();
                break;
            case "setClear":
                cropper.clear();
                break;
            case "enableCropper":
                cropper.enable();
                break;
            case "disableCropper":
                cropper.disable();
                break;
            case "setAspectRatio1":
                cropper.setAspectRatio(1.777);
                break;
            case "setAspectRatio2":
                cropper.setAspectRatio(1.333);
                break;
            case "setAspectRatio3":
                cropper.setAspectRatio(1);
                break;
            case "setAspectRatio4":
                cropper.setAspectRatio(0.666);
                break;
            case "setAspectRatio5":
                cropper.setAspectRatio(NaN);
                break;
            case "setData":
                cropper.setData();
                break;
            case "setCanvasData":
                cropper.setCanvasData();
                break;
            case "setCropBoxData":
                cropper.setCropBoxData()
                break;
            case "moveTo0_0":
                cropper.moveTo(0);
                break;
            case "zoomTo100":
                cropper.zoomTo(1);
                break;
            case "rotate180":
                cropper.rotateTo(180);
                break;
            case "scale_2_1":
                cropper.scale(-2, -1);
                break;
            case "setViewMode0":
                setViewMode(0);
                break;
            case "setViewMode1":
                setViewMode(1);
                break;
            case "setViewMode2":
                setViewMode(2);
                break;
            case "setViewMode3":
                setViewMode(3);
                break;
            default:
                break;
        }
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setActiveImage(cropper.getCroppedCanvas().toDataURL());
        }
    };

    const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: any = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    const saveImage = (e) => {
        if (typeof cropper === "undefined" || cropper === undefined) {
            setErrorMsg("Failed! Please Select Image Dimensions and Try again later.");
            return;
        }
        // Get the cropped image data from canvas
        const croppedImageData = cropper.getCroppedCanvas().toDataURL();

        // Extract base64-encoded data
        const base64Data = croppedImageData.split(',')[1];

        // Convert base64 to Blob
        const blob = base64toBlob(base64Data, selectedFile.type);

        // New File Name
        const fileName = selectedFile.name.split('.');
        const extension = fileName[1];
        const randomNumber = Math.floor(Math.random() * 100);
        const newFileName = fileName[0] + randomNumber + "." + extension;

        // Create a new File
        const croppedFile = new File([blob],
            newFileName,
            {
                type: selectedFile.type,
                lastModified: Date.now()
            });

        // Upload a new Cropped Image
        onCropComplete(e, croppedFile);
        handleClose();
    };

    useEffect(() => {
        if (!selectedFile) return;
        if (selectedFile && selectedFile instanceof File) {
            setActiveImage(URL.createObjectURL(selectedFile));
        }
    }, [selectedFile]);

    const onCrop = (event) => {
        setValueX(parseInt(event.detail.x));
        setValueY(parseInt(event.detail.y));
        setValueWidth(parseInt(event.detail.width));
        setValueHeight(parseInt(event.detail.height));
        setValueRotate(event.detail.rotate);
        setValueScaleX(event.detail.scaleX);
        setValueScaleY(event.detail.scaleY);
    };

    const onToggleOptions = (e, option) => {
        if (typeof cropper === "undefined") return;
        switch (option) {
            case "responsive":
                setOptions((options) => {
                    options.responsive = e.target.checked;
                    return options = {...options};
                });
            break;
            case "restore":
                setOptions((options) => {
                    options.restore = e.target.checked;
                    return options = {...options};
                });
            break;
            case "checkCrossOrigin":
                setOptions((options) => {
                    options.checkCrossOrigin = e.target.checked;
                    return options = {...options};
                });
            break;
            case "checkOrientation":
                setOptions((options) => {
                    options.checkOrientation = e.target.checked;
                    return options = {...options};
                });
            break;
            case "modal":
                setOptions((options) => {
                    options.modal = e.target.checked;
                    return options = {...options};
                });
            break;
            case "guides":
                setOptions((options) => {
                    options.guides = e.target.checked;
                    return options = {...options};
                });
            break;
            case "center":
                setOptions((options) => {
                    options.center = e.target.checked;
                    return options = {...options};
                });
            break;
            case "highlight":
                setOptions((options) => {
                    options.highlight = e.target.checked;
                    return options = {...options};
                });
            break;
            case "background":
                setOptions((options) => {
                    options.background = e.target.checked;
                    return options = {...options};
                });
            break;
            case "autoCrop":
                setOptions((options) => {
                    options.autoCrop = e.target.checked;
                    return options = {...options};
                });
            break;
            case "movable":
                setOptions((options) => {
                    options.movable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "rotatable":
                console.log('rotate');
                setOptions((options) => {
                    options.rotatable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "scalable":
                setOptions((options) => {
                    options.scalable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "zoomable":
                setOptions((options) => {
                    options.zoomable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "zoomOnTouch":
                setOptions((options) => {
                    options.zoomOnTouch = e.target.checked;
                    return options = {...options};
                });
            break;
            case "zoomOnWheel":
                setOptions((options) => {
                    options.zoomOnWheel = e.target.checked;
                    return options = {...options};
                });
            break;
            case "cropBoxMovable":
                setOptions((options) => {
                    options.cropBoxMovable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "cropBoxResizable":
                setOptions((options) => {
                    options.cropBoxResizable = e.target.checked;
                    return options = {...options};
                });
            break;
            case "toggleDragModeOnDblclick":
                setOptions((options) => {
                    options.toggleDragModeOnDblclick = e.target.checked;
                    return options = {...options};
                });
            break;
            default:
            break;
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={show} onClose={handleClose} disableBackdropClick={true} scroll="body">
            <DialogTitle id="form-dialog-title">Crop Image</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.closeBtn}
            >
                <CloseIcon />
            </IconButton>
            {activeImage ? (
                <Paper className={classes.root}>
                    <Alert show={errorMsg ? true : false} variant="danger" onClose={() => setErrorMsg("")} dismissible>
                        {errorMsg}
                    </Alert>

                    <Row noGutters={false} style={{ minHeight: 300 }}>
                        <Col sm={{ span: 8 }}>
                            <Cropper
                             responsive={options.responsive}
                             restore={options.restore}
                             checkCrossOrigin={options.checkCrossOrigin}
                             checkOrientation={options.checkOrientation}
                             modal={options.modal}
                             guides={options.guides}
                             center={options.center}
                             highlight={options.highlight}
                             background={options.background}
                             autoCrop={options.autoCrop}
                             movable={options.movable}
                             rotatable={options.rotatable}
                             scalable={options.scalable}
                             zoomOnTouch={options.zoomOnTouch}
                             zoomOnWheel={options.zoomOnWheel}
                             cropBoxMovable={options.cropBoxMovable}
                             cropBoxResizable={options.cropBoxResizable}
                             toggleDragModeOnDblclick={options.toggleDragModeOnDblclick}
                             zoomable={options.zoomable}
                             key={`viewMode-${viewMode}`}
                             viewMode={viewMode}
                                className="mb-3"
                                src={activeImage}
                                initialAspectRatio={1}
                                preview=".img-preview"
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                autoCropArea={1}
                                style={{ width: '100%' }}
                                onInitialized={(instance) => {
                                    setCropper(instance);
                                }}
                                crop={onCrop}
                            />
                        </Col>

                        <Col sm={{ span: 4 }}>
                            <div className="mb-3">
                                <div className={`img-preview preview-lg img-fluid mb-3 ${classes.preview}`} style={{ width: "256px", height: "160px" }}></div>
                            </div>

                            <div id="cropper-data">
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">X</label>
                                        </div>
                                        <input type="text" value={valueX} className="form-control" id="dataX" placeholder="x" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">Y</label>
                                        </div>
                                        <input type="text" value={valueY} className="form-control" id="dataY" placeholder="y" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">Width</label>
                                        </div>
                                        <input type="text" value={valueWidth} className="form-control" id="dataWidth" placeholder="width" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">Height</label>
                                        </div>
                                        <input type="text" value={valueHeight} className="form-control" id="dataHeight" placeholder="height" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">px</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">Rotate</label>
                                        </div>
                                        <input type="text" value={valueRotate} className="form-control" id="dataRotate" placeholder="rotate" />
                                        <div className="input-group-append">
                                            <span className="input-group-text">deg</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">ScaleX</label>
                                        </div>
                                        <input type="text" value={valueScaleX} className="form-control" id="dataScaleX" placeholder="scaleX" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text">ScaleY</label>
                                        </div>
                                        <input type="text" value={valueScaleY} className="form-control" id="dataScaleY" placeholder="scaleY" />
                                    </div>
                                </div>
                            </div>

                            <div className="btn-group d-flex flex-wrap">
                                <button type="button" title="AspectRatio: 16:9" className="btn btn-primary mb-3" onClick={() => handleAction("setAspectRatio1")}>
                                    <span>16:9</span>
                                </button>
                                <button type="button" title="AspectRatio: 4:3" className="btn btn-primary mb-3" onClick={() => handleAction("setAspectRatio2")}>
                                    <span>4:3</span>
                                </button>
                                <button type="button" title="AspectRatio: 1:1" className="btn btn-primary mb-3" onClick={() => handleAction("setAspectRatio3")}>
                                    <span>1:1</span>
                                </button>
                                <button type="button" title="AspectRatio: 2:3" className="btn btn-primary mb-3" onClick={() => handleAction("setAspectRatio4")}>
                                    <span className="">2:3</span>
                                </button>
                                <button type="button" title="AspectRatio: Free" className="btn btn-primary mb-3" onClick={() => handleAction("setAspectRatio5")}>
                                    <span>Free</span>
                                </button>
                            </div>

                            <Dropdown className="mb-3 media-toogle-button">
                                <Dropdown.Toggle className="w-100" id="dropdown-basic">
                                    Toggle Options
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <ul className="m-0 p-0">
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="responsive" defaultChecked onChange={(e) => onToggleOptions(e, 'responsive')} /> responsive
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="restore" defaultChecked onChange={(e) => onToggleOptions(e, 'restore')} /> restore
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="checkCrossOrigin" defaultChecked onChange={(e) => onToggleOptions(e, 'checkCrossOrigin')} /> checkCrossOrigin
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="checkOrientation" defaultChecked onChange={(e) => onToggleOptions(e, 'checkOrientation')} /> checkOrientation
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="modal" defaultChecked onChange={(e) => onToggleOptions(e, 'modal')} /> modal
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="guides" defaultChecked onChange={(e) => onToggleOptions(e, 'guides')} /> guides
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="center" defaultChecked onChange={(e) => onToggleOptions(e, 'center')} /> center
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="highlight" defaultChecked onChange={(e) => onToggleOptions(e, 'highlight')} /> highlight
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="background" defaultChecked onChange={(e) => onToggleOptions(e, 'background')} /> background
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="autoCrop" defaultChecked onChange={(e) => onToggleOptions(e, 'autoCrop')} /> autoCrop
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="movable" defaultChecked onChange={(e) => onToggleOptions(e, 'movable')} /> movable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="rotatable" defaultChecked onChange={(e) => onToggleOptions(e, 'rotatable')} /> rotatable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="scalable" defaultChecked onChange={(e) => onToggleOptions(e, 'scalable')} /> scalable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="zoomable" defaultChecked onChange={(e) => onToggleOptions(e, 'zoomable')} /> zoomable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="zoomOnTouch" defaultChecked onChange={(e) => onToggleOptions(e, 'zoomOnTouch')} /> zoomOnTouch
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="zoomOnWheel" defaultChecked onChange={(e) => onToggleOptions(e, 'zoomOnWheel')} /> zoomOnWheel
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="cropBoxMovable" defaultChecked onChange={(e) => onToggleOptions(e, 'cropBoxMovable')} /> cropBoxMovable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="cropBoxResizable" defaultChecked onChange={(e) => onToggleOptions(e, 'cropBoxResizable')} /> cropBoxResizable
                                                <span></span>
                                            </label>
                                        </li>
                                        <li className="dropdown-item">
                                            <label className="kt-checkbox">
                                                <input type="checkbox" name="toggleDragModeOnDblclick" defaultChecked onChange={(e) => onToggleOptions(e, 'toggleDragModeOnDblclick')} /> toggleDragModeOnDblclick
                                                <span></span>
                                            </label>
                                        </li>
                                    </ul>

                                </Dropdown.Menu>
                            </Dropdown>

                            <div className="img-cropper" style={{ textAlign: "center" }}>

                                <div className="btn-group">
                                    <button type="button" title="Drag Mode Move" className="btn btn-primary mb-3" onClick={() => handleAction("dragModeMove")}>
                                        <span>
                                            <span className="fa fa-arrows-alt"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Drag Mode Crop" className="btn btn-primary mb-3" onClick={() => handleAction("dragModeCrop")}>
                                        <span>
                                            <span className="fa fa-crop-alt"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Zoom In" className="btn btn-primary mb-3" onClick={() => handleAction("zoomIn")}>
                                        <span>
                                            <span className="fa fa-search-plus"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Zoom Out" className="btn btn-primary mb-3" onClick={() => handleAction("zoomOut")}>
                                        <span>
                                            <span className="fa fa-search-minus"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Move Left" className="btn btn-primary mb-3" onClick={() => handleAction("moveLeft")}>
                                        <span>
                                            <span className="fa fa-arrow-left"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Move Right" className="btn btn-primary mb-3" onClick={() => handleAction("moveRight")}>
                                        <span>
                                            <span className="fa fa-arrow-right"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Move Up" className="btn btn-primary mb-3" onClick={() => handleAction("moveUp")}>
                                        <span>
                                            <span className="fa fa-arrow-up"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Move Down" className="btn btn-primary mb-3" onClick={() => handleAction("moveDown")}>
                                        <span>
                                            <span className="fa fa-arrow-down"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Rotate Left" className="btn btn-primary mb-3" onClick={() => handleAction("rotateLeft")}>
                                        <span>
                                            <span className="fa fa-undo-alt"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Rotate Right" className="btn btn-primary mb-3" onClick={() => handleAction("rotateRight")}>
                                        <span>
                                            <span className="fa fa-redo-alt"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Flip Horizontal" className="btn btn-primary mb-3" onClick={() => handleAction("flipHorizontal")}>
                                        <span>
                                            <span className="fa fa-arrows-alt-h"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Flip Vertical" className="btn btn-primary mb-3" onClick={() => handleAction("flipVertical")}>
                                        <span>
                                            <span className="fa fa-arrows-alt-v"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Crop" className="btn btn-primary mb-3" onClick={() => handleAction("setCrop")}>
                                        <span>
                                            <span className="fa fa-check"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Clear" className="btn btn-primary mb-3" onClick={() => handleAction("setClear")}>
                                        <span>
                                            <span className="fa fa-times"></span>
                                        </span>
                                    </button>
                                </div>

                                <div className="btn-group">
                                    <button type="button" title="Cropping Image" className="btn btn-primary mb-3" onClick={getCropData}>
                                        <span>
                                            <span className="fa fa-crop-alt"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Reset" className="btn btn-primary mb-3" onClick={() => selectedFile && setActiveImage(URL.createObjectURL(selectedFile))} >
                                        <span>
                                            <span className="fa fa fa-sync-alt"></span>
                                        </span>
                                    </button>
                                    <button type="button" title="Save and Uplaod" className="btn btn-success mb-3" onClick={saveImage}>
                                        <span>
                                            <span className="fa fa-save"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Paper>
            ) : (
                <Alert variant="secondary">
                    {t('data.notFound')}
                </Alert>
            )}
        </Dialog>
    )
};

export default MediaCropper;