/* Pagination Helprs */
import React from "react";

export const sortCaret = (order, column) => {
  if (order === "asc")
    return (
      <i className="flaticon2-arrow-up header"></i>
    );
  else if (order === "desc")
    return (
      <i className="flaticon2-arrow-down header"></i>
    );
  return null;
};

export const headerSortingClasses = (column, sortOrder, isLastSorting, colIndex) => (
  (sortOrder === 'asc' || sortOrder === "desc") ? 'sortable-active' : ''
);

export const sortFunction = (a, b, order, dataField, rowA, rowB) => {
  const aNum = parseFloat(a.match(/[\d.]+/)[0]);
  const bNum = parseFloat(b.match(/[\d.]+/)[0]);
  if (order === 'asc') {
    return aNum - bNum;
  } else {
    return bNum - aNum;
  }
};