import React from "react";

function SelectionCheckbox({ isSelected, onChange }) {
    return (
        <>
            <input type="checkbox" style={{ display: "none" }} />
            <label className="kt-checkbox kt-checkbox--single kt-checkbox--solid">
                <input type="checkbox" checked={isSelected} onChange={onChange} />
                <span />
            </label>
        </>
    );
}

function groupingItemOnSelect(props) {
    const { ids, setIds, customerId, paginationProps, onSelectRow, tableData } = props;
    if (ids.some((id) => id === customerId)) {
        setIds(ids.filter((id) => id !== customerId));
    } else {
        const currentValue = tableData.filter((data) => data.ID === customerId);
        if (currentValue[0].status !== "EXCLUDED") {
            const newIds = [...ids];
            newIds.push(customerId);
            setIds(newIds);
        }
    }
    onSelectRow(paginationProps);
}

function groupingAllOnSelect(props) {
    const { isSelected, setIds, tableData, paginationProps, onSelectRow } = props;
    if (!isSelected) {
        const allIds: any = [];
        tableData.forEach((el: any) => {
            if (el.status !== "EXCLUDED") {
                allIds.push(el.ID);
            }
        })
        setIds(allIds);
    } else {
        setIds([]);
    }
    onSelectRow(paginationProps);

    return isSelected;
}

// check official documentations: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Row%20Selection&selectedStory=Custom%20Selection%20Column%20Header%20Style&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
export function getSelectRow(props) {
    const { tableData, ids, setIds, paginationProps, onSelectRow } = props;
    return {
        mode: "checkbox",
        clickToSelect: true,
        hideSelectAll: false,
        selectionHeaderRenderer: () => {
            const isSelected = ids && ids.length > 0;
            // if(ids && ids.length > 0){
            //     isSelected = true
            // }
            // const isSelected = tableData && tableData.length > 0 && tableData.length === ids.length;
            const props = { isSelected, tableData, setIds, paginationProps, onSelectRow };
            return (
                <SelectionCheckbox
                    isSelected={isSelected}
                    onChange={() => groupingAllOnSelect(props)}
                />
            );
        },
        selectionRenderer: (data) => {
            const rowIndex = tableData.findIndex((rec) => {
                return rec.ID === data.rowKey;
            });
            const isSelected = ids.some((el) => el === tableData[rowIndex].ID);
            const props = { ids, setIds, customerId: tableData[rowIndex].ID, paginationProps, onSelectRow, tableData };

            return (
                <SelectionCheckbox
                    isSelected={isSelected}
                    onChange={() => groupingItemOnSelect(props)}
                />
            );
        },
    };
}
