import { Reducer } from 'redux'
import { persistReducer } from "redux-persist";
import { PersistPartial } from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import { action } from 'typesafe-actions';

export enum DataActionTypes {
    PLAN = "data/plan",
    LOCALE = "data/locale",
    LOADING = "data/loading",
    RESET = "data/reset",
    ROWS_PER_PAGE = "data/rows_per_page",
    NOTIFICATION = "data/notification",
    QUALIFICATION = "data/qualification",
    JOBS = "data/jobs",
    DATEFILTER = "data/date"
}

export const plan = (plan: any) => action(DataActionTypes.PLAN, plan);
export const setLanguage = (lang: string) => action(DataActionTypes.LOCALE, { lang });
export const setLoading = (count: number) => action(DataActionTypes.LOADING, { count });
export const reset = () => action(DataActionTypes.RESET, {});
export const setRowsPerPage = (count: number) => action(DataActionTypes.ROWS_PER_PAGE, { count });
export const setNotification = (notification: any) => action(DataActionTypes.NOTIFICATION, { notification });
export const setQualificationAlert = (qualification: any) => action(DataActionTypes.QUALIFICATION, { qualification });
export const setActiveJobs = (jobs: any[]) => action(DataActionTypes.JOBS, { jobs });
export const setFilterDate = (date: any[]) => action(DataActionTypes.DATEFILTER, { date });

const reducer: Reducer<any & PersistPartial> = persistReducer(
    { storage, key: "data" },
    (state: any = { loading: 0, rowsPerPage: 10 }, action) => {

        switch (action.type) {
            case DataActionTypes.PLAN: {
                return { ...state, plan: action.payload };
            }
            case DataActionTypes.LOCALE: {
                return { ...state, locale: action.payload.lang };
            }
            case DataActionTypes.LOADING: {
                return { ...state, loading: action.payload.count > 0 ? action.payload.count : 0 };
            }
            case DataActionTypes.ROWS_PER_PAGE: {
                return { ...state, rowsPerPage: action.payload.count };
            }
            case DataActionTypes.NOTIFICATION: {
                return { ...state, notification: action.payload.notification };
            }
            case DataActionTypes.QUALIFICATION: {
                return { ...state, qualification: action.payload.qualification };
            }
            case DataActionTypes.JOBS: {
                return { ...state, jobs: action.payload.jobs };
            }
            case DataActionTypes.DATEFILTER: {
                return { ...state, date: action.payload.date };
            }
            case DataActionTypes.RESET: {
                return { ...state, loading: 0 };
            }
            default: {
                return state;
            }
        }
    });

export { reducer as dataReducer }
